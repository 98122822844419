<template>
  <div class="rating" >
    <img src="@/assets/icons/star.svg" />
    <p> {{ star ? star : '0' }}</p>
  </div>
</template>

<script>
  export default {
    props: ['star']
  }
</script>

<style lang="scss" scoped>
.rating{
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 63px;
  height: 25px;
  border: 1px solid var(--star-color);
  background-color: color-mix(in srgb, var(--star-color) 10%, transparent 90%);
  border-radius: 20px;
  img {
    width: 10px;
    height: 8px;
  }
  p{
    font-weight: 600;
    font-size: 12px;
    color: var(--star-color);
    margin: 0 !important;
  }
}
</style>